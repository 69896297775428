.root {
    text-align: center;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loader {
    height: 350px;
}