@import (reference) "./TextWidgetDefaultView.module.less";

@mobile: ~"only screen and (max-width: 450px)";

.root {
  &:extend(.root);
}

.container {
  margin: 3.5vh auto;
  &:extend(.container);
}

.headLine {
  font-size: 3vh;
  line-height: 3.5vh;
  &:extend(.headLine);
}

.subHeadLine {
  font-size: 2.5vh;
  line-height: 2.5vh;
  &:extend(.subHeadLine);
}

.text {
  font-size: 2vh;
  line-height: 2vh;
  &:extend(.text);
}

.row {
  margin-bottom: 3vh;
  width: 45vw;
}

@media @mobile {
  .headLine {
    line-height: 3vh;
  }

  .subHeadLine {
    font-size: 2vh;
  }
  
  .row {
    margin-bottom: 2vh;
  }
}