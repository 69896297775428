@import (reference) "./TextWidgetDefaultView.module.less";

.root {
  &:extend(.root);
}

.container {
  width: 93%;
  margin: auto;
  &:extend(.container);
}

.headLine {
  font-size: 6.5vh;
  line-height: 8vh;
  &:extend(.headLine);
}

.text {
  font-size: 3.8vh;
  line-height: 4.8vh;
  &:extend(.text);
}