.green-bg {
    background-color: #43B37A;
}
.white-bg {
    background-color: #ffffff;
}
.red-bg {
    background-color: #E71F64;
}

.hd-green-bg{
    &:extend(.green-bg);
    background-image: url('../../assets/images/hd-green-bg.svg');
}

.hd-white-bg{
    &:extend(.white-bg);
    background-image: url('../../assets/images/hd-white-bg.svg');
}

.y-green-bg{
    &:extend(.green-bg);
    background-image: url('../../assets/images/y-green-bg.svg');
}

.y-white-bg{
    &:extend(.white-bg);
    background-image: url('../../assets/images/y-white-bg.svg');
}

.fullScreen-green-bg{
    &:extend(.green-bg);
    background-image: url('../../assets/images/fullscreen-green-bg.svg');
}

.fullScreen-white-bg{
    &:extend(.white-bg);
    background-image: url('../../assets/images/fullscreen-white-bg.svg');
}

.x-green-bg{
    &:extend(.green-bg);
    background-image: url('../../assets/images/x-green-bg.svg');
}

.x-white-bg{
    &:extend(.white-bg);
    background-image: url('../../assets/images/x-white-bg.svg');
}


// Font colors
.white-text {
    color: #ffffff;
}

.black-text {
    color: #000000;
}
