@import (reference) './ClockWidgetBottomView.module.less';

.root {
    &:extend(.root);
}

.mainContainer {
    border-bottom: 0.1vh solid rgba(255,255,255,0.2);
    &:extend(.mainContainer);
}

.container {
    &:extend(.container);
}

.verticalLine {
    left: 69.95vw;
    &:extend(.verticalLine);
}

.date {
    line-height: 4vh;
    &:extend(.date);

}
.day {
    line-height: 4vh;
    &:extend(.day);
}

.time {
    font-size: 3.7vw;
    line-height: 4vh;
    &:extend(.time);
}
