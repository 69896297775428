@import (reference) './ClockWidgetBottomView.module.less';

.root {
    &:extend(.root);
}

.dateRow {
    height: 60%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    font-family: 'Mulish', sans-serif;
}

.container {
    &:extend(.container);
}

.date {
    font-size: 3vw;
    line-height: 7vh;
    &:extend(.date);
}

.day {
    font-size: 3vw;
    line-height: 7vh;
    &:extend(.day);
}

.timeRow {
  height: 40%;
  font-family: 'Mulish', sans-serif;
}

.time {
    color: white;
    font-size: 9.5vw;
    &:extend(.time);
}