@import (reference) "./TextWidgetTemplates.module.less";

.root {
  height: 100%;
  width: 100%;
  background-position:right top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Mulish', sans-serif;
  &:extend(.green-bg);
  &:extend(.white-text);
}

.container {
  width: 90%;
  margin: 11vh auto;
}

.headLine {
  font-size: 4.5vh;
  line-height: 1.2;
  font-weight: 900;
  letter-spacing: 0.1vw;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
}

.subHeadLine {
  font-size: 5vh;
  line-height: 6vh;
  font-weight: bold;
  letter-spacing: 0.1vw;
}

.text {
  font-size: 3.4vh;
  line-height: 3.8vh;
  font-weight: bold;
  letter-spacing: 0.1vw;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
