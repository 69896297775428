@import (reference) './ClockWidgetBottomView.module.less';

.root {
    &:extend(.root);
}

.mainContainer {
    &:extend(.mainContainer);
}

.container {
    &:extend(.container);
}

.verticalLine {
    left: 34.9vw;
    &:extend(.verticalLine);
}

.date {
    &:extend(.date);
}
.day {
    &:extend(.day);
}

.time {
    &:extend(.time);
}
