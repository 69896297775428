@import (reference) "./TextWidgetDefaultView.module.less";

.root {
  &:extend(.root);
}

.container {
  margin: 6.5vh auto;
  &:extend(.container);
}

.headLine {
  line-height: 8vh;
  &:extend(.headLine);
}

.subHeadLine {
  &:extend(.subHeadLine);
}

.text {
  font-size: 3.8vh;
  line-height: 4.8vh;
  &:extend(.text);
}