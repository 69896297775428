@import (reference) "./ClockWidgetBottomView.module.less";

.root {
  &:extend(.root);
}

.mainContainer {
  &:extend(.mainContainer);
}

.container {
  width: fit-content;
  &:extend(.container);
}

.verticalLine {
  &:extend(.verticalLine);
}

.date {
  font-size: 4.5vw;
  line-height: 3.5vh; //E:4.5
  &:extend(.date);
}
.day {
  font-size: 4.5vw;
  line-height: 3.5vh; //E:4.5
  &:extend(.day);
}

.time {
  font-size: 12.5vw; //14
  line-height: 9vh; //10
  &:extend(.time);
}
