.root {
    background-color: #2F3336;
    width: 100%;
    height: 100%;
}

.mainContainer {
    background-color:#2F3336;
    height: 100%;
    font-family: 'Mulish', sans-serif;
    position: relative;
}

.container {
    width: 80%;
    margin: auto;
}

.verticalLine {
    position: absolute;
    left: 49.9vw;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: rgba(255,255,255,0.2);
}

.dateContainer {
    width: fit-content;
    margin: auto;
}

.date {
    color: white;
    font-size: 3.7vw;
    font-weight: 300;
    line-height: 8vh;
    letter-spacing: 0.1vw;
    text-transform: capitalize;
}

.day {
    color: white;
    font-size: 3.7vw;
    font-weight: 800;
    line-height: 8vh;
    letter-spacing: 0.1vw;
}

.time {
    color: white;
    font-size: 10.2vw;
    font-weight: 300;
    line-height: 24vh;
}