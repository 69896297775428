.root {
    background-color: #2F3336;
    height: 100%;
    width: 100%;
    font-family: 'Mulish', sans-serif;
}

.mainContainer {
  background-color:#2F3336;
  height: 100%;
  font-family: 'Mulish', sans-serif;
  position: relative;
}

.container {
  width: 80%;
  margin: auto;
}

.verticalLine {
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: rgba(255,255,255,0.2);
}

.dateContainer {
  width: fit-content;
  margin: auto;
}

.date {
  text-transform: capitalize;
  color: white;
  font-weight: 300;
  letter-spacing: 0.1vw;
}

.day {
  color: white;
  font-weight: 800;
  letter-spacing: 0.1vw;
}

.time {
  color: white;
  font-weight: 300;
}

.center {
  text-align: center;
  align-self: center;
}

.textAlignCenter {
  text-align: center;
}

.alignMiddle {
  align-self: center;
}

.temperature {
  color: white;
  font-weight: 300;
  letter-spacing: 0vw;
}

.tempForOne {
  margin-left: -0.9vw;
}

.weatherImage {
  vertical-align: top;
  height: auto;
}

.cityName {
  color: white;
  font-weight: 300;
  letter-spacing: 0.1vw;
}