@import (reference) './base.module.less';

.mainContainer {
    &:extend(.mainContainer);
}

.container {
    &:extend(.container);
}

.verticalLine {
    left: 49.9vw;
    width: 1px;
    &:extend(.verticalLine);
}

.dateContainer {
    &:extend(.dateContainer);
}

.date {
    line-height: 8vh;
    font-size: 3.7vw;
    &:extend(.date);
}

.day {
    line-height: 8vh;
    font-size: 3.7vw;
    &:extend(.day);
}

.time {
    font-size: 10.2vw;
    line-height: 18vh;
    &:extend(.time);
}

.center {
    &:extend(.center);
}

.temperature {
    font-size: 7.2vw;
    line-height: 13vh;
    &:extend(.temperature);
}

.tempForOne {
    &:extend(.temperature);
}

.weatherImage {
    width: 7.2vw;
    line-height: 13vh;
    &:extend(.weatherImage);
}

.cityName {
    font-size: 3.7vw;
    line-height: 8vh;
    margin-left: 11vw;
    &:extend(.cityName);
}

