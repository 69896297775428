.container {
  background-color: #000;
  height: 100%;
  width: 100%;
}

.container > div {
  background-color: #000;
  height: 100%;
  width: 100%;
}
