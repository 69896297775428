@import (reference) './base.module.less';

.mainContainer {
    &:extend(.mainContainer);
}

.container {
    &:extend(.container);
}

.verticalLine {
    left: 70vw;
    width: 1px;
    &:extend(.verticalLine);
}


.dateContainer {
    &:extend(.dateContainer);
}

.date {
    line-height: 8vh;
    font-size: 3.7vw;
    &:extend(.date);
}

.day {
    line-height: 8vh;
    font-size: 3.7vw;
    &:extend(.day);
}

.time {
    font-size: 10.2vw;
    line-height: 24vh;
    &:extend(.time);
}

.center {
    &:extend(.center);
}

.textAlignCenter {
    &:extend(.textAlignCenter);
}

.temperature {
    font-size: 8vw;
    line-height: 13vh;
    &:extend(.temperature);
}

.alignMiddle {
    &:extend(.alignMiddle);
}

.weatherImage {
    width: 8vw;
    margin-left: 0.5vw;
    &:extend(.weatherImage);
}

.cityName {
    font-size: 2.7vw;
    line-height: 8vh;
    margin-top: -2vh;
    &:extend(.cityName);
}

