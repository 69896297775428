@import (reference) "./TextWidgetDefaultView.module.less";

.root {
  &:extend(.root);
}

.container {
  margin: 12vh auto;
  &:extend(.container);
}

.headLine {
  letter-spacing: 0.2vw;
  &:extend(.headLine);
}

.subHeadLine {
  font-size: 4.5vh;
  line-height: 5vh;
  letter-spacing: 0.2vw;
  &:extend(.subHeadLine);
}

.text {
  font-size: 3vh;
  line-height: 4vh;
  letter-spacing: 0.2vw;
  &:extend(.text);
}
