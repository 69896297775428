@import (reference) './base.module.less';

.mainContainer {
    &:extend(.mainContainer);
}

.container {
    &:extend(.container);
}

.verticalLine {
    left: 34.9vw;
    width: 1px;
    &:extend(.verticalLine);
}

.date {
    font-size: 3.7vw;
    line-height: 8vh;
    &:extend(.date);
}

.day {
    line-height: 8vh;
    font-size: 3.7vw;
    &:extend(.day);
}

.timeForOne {
    margin-left: -1.2vw;
}

.time {
    font-size: 10.2vw;
    line-height: 18vh;
    &:extend(.time);
}

.center {
    &:extend(.center);
}

.temperature {
    font-size: 7.2vw;
    line-height: 13vh;
    &:extend(.temperature );
}

.tempForOne {
    &:extend(.tempForOne);
}

.weatherImage {
    width: 7.2vw;
    &:extend(.weatherImage);
}

.cityName {
    font-size: 3.7vw;
    line-height: 8vh;
    margin-left: 1.6vw;
    &:extend(.cityName);
}

