@import (reference) "./base.module.less";

.root {
  &:extend(.root);
}

.mainContainer {
  background-color: #2f3336;
  height: 100%;
  font-family: "Mulish", sans-serif;
  position: relative;
}

.verticalLine {
  left: 49.9vw;
  width: 1px;
  &:extend(.verticalLine);
}

.date {
  font-size: 3.7vw;
  line-height: 4vh;
  &:extend(.date);
}

.day {
  font-size: 3.7vw;
  line-height: 4vh;
  &:extend(.day);
}

.weatherImage {
  width: 3.7vw;
  vertical-align: middle;
  height: auto;
}

.temperature {
  font-size: 3.7vw;
  line-height: 4vh;
  &:extend(.temperature);
}

.cityName {
  font-size: 3.7vw;
  line-height: 4vh;
  &:extend(.cityName);
}
