@import (reference) "./base.module.less";

.mainContainer {
  &:extend(.mainContainer);
}

.container {
  width: fit-content;
  &:extend(.container);
}

.verticalLine {
  left: 49.9vw;
  width: 1px;
  &:extend(.verticalLine);
}

.date {
  font-size: 4.5vw;
  line-height: 3.5vh; //3.5, E:4.5
  &:extend(.date);
}

.day {
  font-size: 4.5vw;
  line-height: 3.5vh; //3.5, E:4.5
  &:extend(.day);
}

.timeForOne {
  margin-left: -1.2vw;
}

.time {
  font-size: 12.5vw; //12.5, E:14
  line-height: 9vh; //9, E:10
  &:extend(.time);
}

.center {
  &:extend(.center);
}

.temperature {
  font-size: 10vw; //10, E: 11.2
  line-height: 5vh; //5 E:8
  &:extend(.temperature);
}

.tempForOne {
  margin-left: -1.2vw;
}

.weatherImage {
  width: 10vw; //10, E:11
  &:extend(.weatherImage);
}

.cityName {
  font-size: 4.5vw;
  line-height: 3.5vh; //3.5, E:4
  &:extend(.cityName);
}
