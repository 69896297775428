@import (reference) "./base.module.less";

.root {
  &:extend(.root);
}

.dateAndTimeRow {
  height: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-family: "Mulish", sans-serif;
}

.container {
  &:extend(.container);
}

.content {
  width: auto;
  margin: auto;
  max-width: 75%;
}

.date {
  font-size: 8.3vw;
  line-height: 5.2vh;
  &:extend(.date);
}

.day {
  font-size: 8.3vw;
  line-height: 6.2vh;
  &:extend(.day);
}

.time {
  font-size: 20vw;
  line-height: 14vh;
  &:extend(.time);
}

.timeForOne {
  margin-left: -1.2vw;
}

.weatherRow {
  height: 50%;
  font-family: "Mulish", sans-serif;
}

.textAlignCenter {
  &:extend(.textAlignCenter);
}

.temperature {
  font-size: 20vw;
  line-height: 14vh;
  &:extend(.temperature);
}

.tempForOne {
  &:extend(.tempForOne);
}

.alignMiddle {
  &:extend(.alignMiddle);
}

.weatherImage {
  width: 20vw;
  line-height: 14vh;
  vertical-align: middle;
  &:extend(.weatherImage);
}

.cityNameForTempOne {
  margin-left: 1.2vw;
}

.cityName {
  font-size: 8.3vw;
  line-height: 6.2vh;
  margin-top: -2vh;
  &:extend(.cityName);
}
