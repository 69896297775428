@import (reference) './base.module.less';

.root {
    &:extend(.root);
}

.dateAndTimeRow {
    height: 60%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    font-family: 'Mulish', sans-serif;
}

.container {
    &:extend(.container);
}

.date {
    font-size: 3vw;
    line-height: 7vh;
    &:extend(.date);
}

.day {
    line-height: 7vh;
    font-size: 3vw;
    &:extend(.day);
}

.time {
    font-size: 9.5vw;
    line-height: 18vh;
    &:extend(.time);
}

.timeForOne {
    margin-left: -1.2vw;
}

.weatherRow {
    height: 40%;
    font-family: 'Mulish', sans-serif;
}

.textAlignCenter {
    &:extend(.textAlignCenter);
}

.temperature {
    font-size: 8vw;
    line-height: 13vh;
    &:extend(.temperature);
}

.tempForOne {
    &:extend(.tempForOne);
}

.alignMiddle {
    &:extend(.alignMiddle);
}

.weatherImage {
    width: 8vw;
    &:extend(.weatherImage);
}

.cityName {
    font-size: 2.7vw;
    line-height: 8vh;
    margin-top: -2vh;
    &:extend(.cityName);
}