@import (reference) "./TextWidgetDefaultView.module.less";

.root {
  &:extend(.root);
}

.container {
  width: 100%;
  font-family: 'Mulish', sans-serif;
}

.headLine {
  font-size: 3vh;
  line-height: 1.5vh;
  &:extend(.headLine);
}